
import { Component, Vue } from 'vue-property-decorator'
import { FileInfo } from '@/types/common'
import { DetailInfo } from '@/types/qualitySafety'
import UploadFile from '@/components/uploadFile/Index.vue'
import { ElForm } from 'element-ui/types/form'
import { ProjectUser } from '@/types/project'

@Component({
  components: { UploadFile }
})
export default class QualitySafetyAdd extends Vue {
  private formInfo: DetailInfo = {
    projectId: '',
    /** 项目名称 */
    projectName: '',
    /** 事件类型 */
    safetyType: '',
    /** 事件详情 */
    safetyDetail: '',
    /** 事件位置 */
    occurAddr: '',
    /** 发生时间 */
    occurDate: '',
    /** 负责人Id */
    leaderId: '',
    resourceList: []
  }

  private projectUserList: ProjectUser[] = []
  private safetyType = []
  private rulesForm = {
    projectId: [
      { required: true, message: '请选择项目名称', trigger: 'change' }
    ],
    safetyType: [
      { required: true, message: '请选择事件类型', trigger: 'change' }
    ],
    safetyDetail: [
      { required: true, message: '请输入事件详情', trigger: 'change' }
    ],
    leaderId: [
      { required: true, message: '请选择负责人', trigger: 'change' }
    ],
    occurDate: [
      { required: true, message: '请选择发生日期', trigger: 'change' }
    ],
    occurAddr: [
      { required: true, message: '请输入发生位置', trigger: 'change' }
    ]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getSafetyType()
  }

  getSafetyType () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'safetyType' }).then(res => {
      this.safetyType = res.safetyType || []
    })
  }

  selectProject (value: string) {
    this.formInfo.leaderId = ''
    value && this.getProjectUserList()
  }

  // 获取项目下所有人信息
  getProjectUserList () {
    this.$axios.get(this.$apis.project.selectProjectUserByList, {
      projectId: this.formInfo.projectId
    }).then((res: { list: ProjectUser[]; total: number }) => {
      this.projectUserList = res.list || []
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.formInfo.resourceList.push(file)
  }

  imgRemove (index: number) {
    this.formInfo.resourceList.splice(index, 1)
  }

  onSubmit () {
    (this.$refs.formInfo as ElForm).validate((valid) => {
      if (valid) {
        this.$axios.post(this.$apis.qualitySafety.insertQualitySafety, this.formInfo).then(() => {
          this.$message.success('添加成功')
        }).finally(() => {
          this.onCancel()
        })
      }
    })
  }

  onCancel () {
    this.$router.push({ name: 'qualitySafety' })
  }
}
